import PropTypes from 'prop-types';
// next
import dynamic from 'next/dynamic';
import { width } from '@mui/system';
import { Box } from '@mui/material';
//
const Header = dynamic(() => import('./header/Header'), { ssr: false });
const HeaderSimple = dynamic(() => import('./header/HeaderSimple'), { ssr: false });
const Footer = dynamic(() => import('./footer/Footer'), { ssr: false });
const FooterSimple = dynamic(() => import('./footer/FooterSimple'), { ssr: false });

// ----------------------------------------------------------------------

Layout.propTypes = {
  children: PropTypes.node,
  disabledFooter: PropTypes.bool,
  disabledHeader: PropTypes.bool,
  simpleFooter: PropTypes.bool,
  simpleHeader: PropTypes.bool,
  transparentHeader: PropTypes.bool,
  sx: PropTypes.any,
  containerSX: PropTypes.any,
};

export default function Layout({
  children,
  transparentHeader,
  disabledHeader,
  disabledFooter,
  simpleHeader,
  simpleFooter,
  sx,
  containerSX = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
}) {
  return (
    <Box sx={containerSX}>
      <div>
        {disabledHeader ? null : (
          <>
            {simpleHeader ? (
              <HeaderSimple transparent={transparentHeader} />
            ) : (
              <Header transparent={transparentHeader} />
            )}
          </>
        )}
      </div>

      <Box sx={sx}>{children}</Box>

      <div>{disabledFooter ? null : <>{simpleFooter ? <FooterSimple /> : <Footer />}</>}</div>
    </Box>
  );
}
