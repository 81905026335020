// layouts
import Layout from '../src/layouts';
// components
import { Page } from '../src/components';

import { Home } from 'src/sections/home';
import useRole from 'src/hooks/useRole';
import { StoreConst } from 'src/store/local';
import Dashboard from './dashboard';
import { styled } from '@mui/material';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
	// paddingTop: HEADER_MOBILE_HEIGHT,
	backgroundColor: theme.palette.background.neutral,
	[theme.breakpoints.up('md')]: {
		// paddingTop: HEADER_DESKTOP_HEIGHT,
	},
}));

const Index = () => {
	const isClient = useRole(StoreConst.CLIENT);
	const isLogin = isClient != null;

	return (
		<Page title="Home">
			<RootStyle>
				<RootStyle>{isLogin ? <Dashboard /> : <Home />}</RootStyle>
			</RootStyle>
		</Page>
	);
};

export default Index;

// ----------------------------------------------------------------------
Index.getLayout = function getLayout(page) {
	return (
		<Layout sx={{ flex: 1 }} simpleFooter>
			{page}
		</Layout>
	);
};
